body {
  margin: 0;
  font-family: "Roboto", sans-serif;
}

.coming-soon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #ece9e6 0%, #ffffff 100%);
  text-align: center;
}

.coming-soon-content {
  width: 80%;
  max-width: 1200px;
  padding: 60px 40px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.coming-soon-title {
  font-size: 3rem;
  margin-bottom: 20px;
  color: #333;
}

.coming-soon-message {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #666;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.button {
  text-decoration: none;
  padding: 12px 30px;
  font-size: 1.1rem;
  color: white;
  border-radius: 50px;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

.contact-button {
  background-color: #007bff;
}

.contact-button:hover {
  background-color: #0056b3;
  transform: translateY(-2px);
}

.linkedin-button {
  background-color: #0077b5;
}

.linkedin-button:hover {
  background-color: #005582;
  transform: translateY(-2px);
}

.logo {
  height: 10vw;
}
